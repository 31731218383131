<template>
  <div :class="$style.templates">
    <div :class="$style.header">
      <p>Переменные указываются в фигурных скобках (например <code>{name}</code>, где name - название продукта, <code>{vendor_code}</code> - артикул, <code>{discount_price}</code> - цена после скидки, <code>{purpose}</code> - предназначение).</p>
      <div v-for="template in templates" :key="template.entity" :class="$style.template">
        <h3>{{ template.entity }}</h3>
        <el-form @submit.prevent :model="template.seo" label-width="200px">
          <el-form-item label="Заголовок">
            <el-input v-model="template.seo.title"></el-input>
          </el-form-item>
          <el-form-item label="Описание">
            <el-input v-model="template.seo.description"></el-input>
          </el-form-item>
          <el-form-item label="Заголовок для соц.сетей">
            <el-input v-model="template.seo.ogTitle"></el-input>
          </el-form-item>
          <el-form-item label="Описание для соц.сетей">
            <el-input v-model="template.seo.ogDescription"></el-input>
          </el-form-item>
        </el-form>
        <div :class="$style.action">
          <Button type="primary" @click="saveTemplate(template)">
            Сохранить
          </Button>
          <Button v-if="template.entity == 'products'" type="primary" style="margin-left: 1rem;" @click="updateProductsSEOs">
            Обновить продукты 
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import delivery from '@/delivery'
import Button from '@/components/atoms/Button'

export default {
  components: { Button },
  data() {
    return {
      templates: [],
    }
  },
  async created() {
    await this.setSeoTemplates()
  },
  methods: {
    async setSeoTemplates() {
      const { value, error } = await delivery.ContentServiceCore.SeoTemplatesActions.getList()
      if (error) return

      this.templates = value.result.data;
    },
    async saveTemplate(template) {
      const { error } = await delivery.ContentServiceCore.SeoTemplatesActions.saveOne(template)

      if (error) {
        alert('Ошибка обновления шаблона: ' + error)
      } else {
        alert('Шаблон успешно обновлён')
      }

      if (error) return

      await this.setSeoTemplates()
    },
    async updateProductsSEOs() {
      const isConfirm = confirm(
        `Вы точно хотите обновить SEO всех продуктов?`
      )
      if (isConfirm) {
        const { error } = await delivery.ProductsCore.ProductsActions.updateProductsSEOsByTemplate()
        if (error) {
          alert('Ошибка обновления продуктов: ' + error)
        } else {
          alert('SEO всех продуктов обновляется. Уведомление об окончании придёт в Telegram')
        }
      }
    }
  }
}
</script>

<style lang="scss" module>
.templates {
  width: 100%;
  padding: 2rem;
  .template {
    margin-top: 2rem;
    &:not(:last-child) {
      padding-bottom: 0.5rem;
      border-bottom: 0.063rem solid $light-gray;
    }
    h3 {
      margin-bottom: 1rem;
    }
    .action {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
}
</style>